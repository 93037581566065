/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useMasterLayout } from 'src/app/context/MasterContext'
import { useClickOutside } from 'src/app/hooks/click-outside-hook'
import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import ProfileScreens from 'src/app/modules/profile/Screens'
import { RootState } from 'src/setup'
import { Card, GButton } from '../../Libs'
import GIcon from '../../Libs/Icon/GIcon'
import { KTSVG } from '../../Libs/Icon/KTSVG'
import BasicModal from '../../Widgets/ModalCollection/BasicModal'
import { DefaultTitle } from './DefaultTitle'
import setupWebsocket from 'src/setup/websocket/SetupWebsocket'
import { Bell } from 'react-swm-icon-pack'
import { Tooltip } from 'react-tooltip'
import { getAuthState } from 'src/setup/hooks/getAuthState'
import { checkJson } from 'src/app/utils/cek-json-utils'
import { IPosNotification } from 'src/app/modules/permissions/models/User.model'
import { handleResponse } from 'src/setup/hooks/socketInterceptor'

const HeaderWrapper: FC = () => {
  const baseUrl = process.env.REACT_APP_API_URL
  const { minimize, setMinimize } = useMasterLayout()
  const user: any = useSelector<RootState>(({ auth }) => auth?.user, shallowEqual)
  const dispatch = useDispatch()
  const [clickOutsideRef, isShow, setIsShow] = useClickOutside(false)
  const [isShowNotif, setIsShowNotif] = useState<boolean>(true)
  const [logoutModal, setLogoutModal] = useState<boolean>(false)
  const [latestNotif, setLatestNotif] = useState<IPosNotification | null>(null)
  const { token } = getAuthState()

  const handleLogout = () => {
    setIsShow(false)
    setupWebsocket('logout', { type: 'GET' })
    dispatch(AuthRedux.actions.logout())
  }

  function getLatestActiveNotification(data: IPosNotification[]) {
    const now = new Date();

    return data
      .filter(item =>
        new Date(item.start_date) <= now && now <= new Date(item.end_date)
      )
      .sort((a: IPosNotification, b: IPosNotification) => new Date(b?.start_date).getTime() - new Date(a?.start_date).getTime())[0] || null;
  }

  useEffect(() => {
    setIsShowNotif(Boolean(latestNotif?.text && latestNotif?.text.length > 0))
  }, [latestNotif?.text])

  useEffect(() => {
    const socket = new WebSocket(`${baseUrl}/pos_notification?token=${token ?? ''}`)
    const interval = setInterval(() => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({ type: 'GET' }));
      }
    }, 1000);

    socket.onmessage = (event) => {
      const response = checkJson(event.data) ? JSON.parse(event.data) : ''

      if (response?.response_schema?.response_code === '200') {
        const notifList: IPosNotification[] = []
        const now = new Date()
        response?.response_output?.detail?.map((item: IPosNotification) => {
          if (now >= new Date(item?.start_date) && now <= new Date(item?.end_date)) {
            notifList.push(item)
          }
        })
        setLatestNotif(getLatestActiveNotification(notifList))
      } else {
        handleResponse(event)
      }
    }
    return () => {
      clearInterval(interval);
      socket.close()
    }
  }, [])

  return (
    <>
      <div
        className={clsx(
          'fixed top-0 right-0 z-[99] h-[55px] p-0 shadow-none bg-white flex items-stretch transition-all duration-300',
          'lg:h-[80px]',
          'dark:bg-inverse-primary-light-1 dark:text-white',
          { 'left-0 lg:left-[264px]': !minimize },
          { 'left-0 lg:left-[72px]': minimize }
        )}
      >
        <div className={clsx('px-8 flex items-stretch justify-between w-full mx-auto')}>
          <div className='flex items-center mr-1 lg:hidden'>
            <div
              onClick={() => setMinimize(!minimize)}
              className='inline-flex items-center justify-center w-10 h-10 px-4 border-0 shadow-none outline-none'
            >
              <KTSVG path='/media/icons/IconBurger.svg' className='text-neutral-100' />
            </div>
          </div>

          <div className={clsx('hidden items-stretch justify-between', 'lg:grow lg:flex')}>
            <div className='flex items-center'>
              <DefaultTitle />
            </div>
          </div>

          <div className='flex items-stretch'>
            <div className='flex items-center justify-center'>
              <GButton
                className='bg-transparent border-transparent min-w-8 h-10'
                onClick={() => setIsShowNotif(!isShowNotif)}
              >
                <Bell color='#CF1E26' data-tooltip-id='pos-notification' />

                <Tooltip isOpen={isShowNotif} id='pos-notification' className='rounded-xl'>
                  <div className='flex flex-col gap-2 divide-y'>
                    <div dangerouslySetInnerHTML={{ __html: latestNotif?.text as string | TrustedHTML }} />
                  </div>
                </Tooltip>
              </GButton>
              <div className='relative' ref={clickOutsideRef}>
                <div
                  onClick={() => setIsShow(!isShow)}
                  className='flex items-center cursor-pointer select-none'
                >
                  <div className='text-end'>
                    <div className='font-semibold text-fs-9'>Hi, {user?.employee_name}</div>
                    <div className='text-fs-10 text-[#98A2B3]'>{user?.email}</div>
                  </div>
                  <div className='flex items-center justify-center ml-4'>
                    <div className='w-[40px] h-[40px] bg-[#E11D48] rounded-lg flex items-center justify-center'>
                      <span className='text-white text-[14px]'>
                        {user?.username?.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  </div>
                </div>
                {isShow && (
                  <Card.Root className='absolute right-0 top-16 w-[400px] rounded-lg animate-opacity-to-full'>
                    <div className='flex items-center justify-between py-4 px-9'>
                      <div className='flex items-center'>
                        <div className='flex items-center justify-center w-10 h-10 font-bold rounded-full bg-neutral-20 text-primary'>
                          {user?.employee_name?.charAt(0).toUpperCase()}
                        </div>
                        <div className='ml-4'>
                          <div className='font-semibold text-fs-9'>{user?.employee_name}</div>
                          <div className='text-fs-10 text-neutral-70'>{user?.email}</div>
                        </div>
                      </div>

                      <div
                        onClick={() => {
                          setLogoutModal(true)
                        }}
                        className='flex items-center justify-center w-10 h-10 rounded-lg cursor-pointer bg-danger-light-2'
                      >
                        <GIcon icon='IconSignOut' className='text-danger' />
                      </div>
                    </div>

                    <div className='border-t border-neutral-30 text-fs-9'>
                      <Link to={ProfileScreens.PROFILE.PATH}>
                        <div className='py-6 px-9'>My Profile</div>
                      </Link>
                    </div>
                  </Card.Root>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <BasicModal
        header='Logout'
        show={logoutModal}
        handleSuccess={handleLogout}
        handleClose={() => setLogoutModal(false)}
        positiveLabel='Logout'
        negativeLabel='Cancel'
        size='sm'
      >
        Do you want to logout?
      </BasicModal>
    </>
  )
}

export default HeaderWrapper
