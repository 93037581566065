import {FC, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useWebTitle} from 'src/app/hooks/title-hook'
import AuthRedux from '../../redux/AuthRedux'
import NumberPad from 'src/app/components/Libs/NumberPad/NumberPad.component'
import {checkJson} from 'src/app/utils/cek-json-utils'
import setupWebsocket from 'src/setup/websocket/SetupWebsocket'
import ModalConfirm from 'src/app/components/Widgets/ModalCollection/ModalConfirm'

const Login: FC = () => {
  const [pin, setPin] = useState('')
  const [isError, setIsError] = useState(false)
  const [popupForceLogin, setPopupForceLogin] = useState<boolean>(false)
  const inputPin = (number: number) => {
    setPin((prevPin) => prevPin + number.toString()) // Memperbarui pin dengan cara yang lebih aman
  }
  const clearPin = () => {
    setIsError(false)
    setPin('')
  }
  const dispatch = useDispatch()
  useWebTitle('Login')

  const signIn = (isForce: boolean) => {
    setIsError(false)
    const payload = {
      type: 'POST',
      data: {
        pin,
        force_login: isForce,
      },
    }

    const ws = setupWebsocket('login', payload)
    ws.onmessage = (event) => {
      const response = checkJson(event.data) ? JSON.parse(event.data) : ''
      if (response && response.response_schema.response_code === '200') {
        dispatch(AuthRedux.actions.login(response.response_output.detail))
        setPopupForceLogin(false)
      } else if (
        response.response_schema.response_code === '400' &&
        response.response_schema.response_message !== 'User not found!'
      ) {
        setPopupForceLogin(true)
      } else {
        setIsError(true)
      }
    }
  }
  const buttonClassDisabled = (isDisabled: boolean) => `${isDisabled ? 'opacity-40' : ''}`

  const performForceLogin = () => {
    signIn(true)
  }

  return (
    <div data-testid='login-email-page' className='w-full'>
      <div className='flex flex-col items-center justify-center gap-14 w-94 h-204 rounded-lg'>
        <div className='flex flex-col items-start p-0 gap-7 w-65 h-96'>
          {/* Input field */}
          <div className='flex w-full flex-col p-0 gap-1 h-11'>
            <input
              type='password'
              className={`${
                isError ? 'border-[#E2826A]' : 'border-[#D0D5DD] '
              } box-border flex items-center p-3 gap-2 h-11 bg-white border border-solid border-[#D0D5DD] shadow-[0_1px_2px_rgba(16,24,40,0.05)] rounded-lg`}
              placeholder='Enter PIN'
              readOnly={true}
              value={pin}
            />
            {isError && (
              <span
                className={` flex items-center w-full h-5 text-center text-[#C55143] text-sm font-normal`}
              >
                Wrong PIN, please try again.
              </span>
            )}
          </div>
          {/* Keypad */}
          <NumberPad onInput={(value) => inputPin(value)} onClear={() => clearPin()}></NumberPad>
          {/* Submit Button */}
          <button
            className={`flex justify-center items-center p-3 w-65 h-11 bg-[#CF1E26] rounded-lg w-full text-white ${buttonClassDisabled(
              pin.length < 6
            )}`}
            disabled={pin.length < 6}
            onClick={() => signIn(false)}
          >
            Sign In
          </button>
        </div>
      </div>
      <ModalConfirm
        show={popupForceLogin}
        handleSuccess={() => {
          performForceLogin()
        }}
        handleClose={() => {
          setPopupForceLogin(false)
        }}
        header={'User is login in another device. Are you sure you want to continue?'}
        type={'WARNING'}
        positiveLabel='Yes'
        negativeLabel='No'
      />
    </div>
  )
}

export default Login
