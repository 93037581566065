
const DayStartEndScreens = {
  DAYSTARTEND: {
    PATH: '/day-start',
    TITLE: 'Day Start/End',
    TITLE_ALT: 'Day Start',
  },
}

export default DayStartEndScreens
