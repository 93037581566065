
const ShiftLogScreens = {
  SHIFT_LOG: {
    PATH: '/shift-log',
    TITLE: 'Shift Log',
    TITLE_ALT: 'Shift Log',
  },
  SHIFT_LOG_DETAIL: {
    PATH: '/shift-log/detail/:id',
    TITLE: 'Shift Log Detail',
    TITLE_ALT: 'Shift Log Detail',
  },
}

export default ShiftLogScreens
