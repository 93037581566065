import {FC, MouseEventHandler} from 'react'
import {GButton} from '../../Libs/Button/Button.component'

interface ModalFooterProps {
  handleSuccess?: MouseEventHandler
  handleClose?: MouseEventHandler
  positiveLabel?: string
  negativeLabel?: string
  loading?: boolean
  disabled?: boolean
  isShowNegativeButton?: boolean
}

const OrderModalFooter: FC<ModalFooterProps> = ({
  handleSuccess,
  handleClose,
  positiveLabel,
  negativeLabel,
  loading,
  disabled,
  isShowNegativeButton = true
}) => {
  const className = `text-sm font-medium ${isShowNegativeButton ? 'min-w-[138px] w-full' : 'w-[138px]'}`;
  return (
    <div className='flex gap-3 justify-end items-center w-full px-6 py-5 border-t border-neutral-30'>
      {isShowNegativeButton && <GButton
        onClick={handleClose}
        type='button'
        variant='OUTLINED'
        className={className}
        disabled={disabled}
        data-testid='close-button'
      >
        {negativeLabel ?? 'No'}
      </GButton>}

      <GButton
        onClick={handleSuccess}
        className={className}
        type='button'
        loading={loading}
        disabled={disabled}
        data-testid='success-button'
      >
        {positiveLabel ?? 'Yes'}
      </GButton>
    </div>
  )
}

export default OrderModalFooter
