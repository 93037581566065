import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, ArrowDown, Download, EyeOpen, Trash2 } from 'react-swm-icon-pack';

const SalesList: React.FC<{data:Array<any>}> = ({data=[]}) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };
    
    const handlePageChange = (page: number) => {
    setCurrentPage(page);
    };
    const totalPages = Math.ceil(data.length / rowsPerPage);
  return (
    <div className="flex flex-col grow h-full bg-white rounded-lg border border-[#EAECF0]">
      <div className='flex flex-col h-full'>
        <div className="flex-none px-5 py-6 text-lg font-poppins border-b border-[#EAECF0]">Sales List</div>
        <div className='flex-1 flex-col h-full overflow-y-auto'>
          <table className="min-w-full">
            <thead className='border-b border-[#EAECF0] bg-[#F9FAFB] text-sm text-left text-[#667085]'>
              <tr>
                <td className="px-5 py-6">Transaction Number</td>
                <td className="px-5 py-6">Section</td>
                <td className="px-5 py-6">Table Number</td>
                <td className="px-5 py-6">Pax Total</td>
                <td className="px-5 py-6">
                  <div className="flex items-center gap-1">
                    <span>Member Type</span>
                    <ArrowDown size={16} color={"#667085"} />
                  </div>
                </td>
                <td className="px-5 py-6">
                  <div className="flex items-center gap-1">
                    <span>Grand Total</span>
                    <ArrowDown size={16} color={"#667085"} />
                  </div>
                </td>
                <td className="px-5 py-6">Action</td>
              </tr>
            </thead>
            <tbody>
                <tr key={'quick-service'} className="border-b border-[#EAECF0] text-xs text-left text-[#101828]">
                  <td className="px-5 py-6">SXRYSJ1293848192011234567890</td>
                  <td className="px-5 py-6">-</td>
                  <td className="px-5 py-6">Quick Service</td>
                  <td className="px-5 py-6">1</td>
                  <td className="px-5 py-6">Non Member</td>
                  <td className="px-5 py-6">Rp27.300</td>
                  <td className="px-5 py-6 flex flex-row h-full grow items-center gap-1"><Download color={"#667085"}/><EyeOpen color={"#667085"}/><Trash2 color={"#667085"}/></td>
                </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-row justify-between px-3 py-6 border-t border-[#EAECF0]">
          <div className='flex flex-row items-center grow gap-3'>
            <select value={rowsPerPage} onChange={handleChangeRowsPerPage} className="ml-2 bg-[#ffffff] border border-solid border-[#D0D5DD] px-2.5 py-2 w-[60px] h-9">
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <label className='text-xs text-[#667085]'>
              Rows per page
            </label>
          </div>
          <div className="flex items-center gap-3">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="border-0 p-5"
            >
              <ChevronLeft/>
            </button>
            <span className='px-5 py-3 rounded-lg border border-[#F08575] text-xs text-[#475467] bg-[#FEF7F3]'>
              {currentPage}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="border-0 p-5"
            >
              <ChevronRight/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesList;
