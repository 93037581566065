import {FC, ReactNode} from 'react'
import {cn} from 'src/app/utils/cn-utils'
import {FormError} from '../FormError'
import {CheckboxItemProps, DotSizes, FormCheckboxProps, SwitchSizes} from './Checkbox.types'

const Container: FC<{children: ReactNode}> = ({children}) => {
  return (
    <div className='flex items-center flex-1'>
      <label className='flex items-center'>{children}</label>
    </div>
  )
}

const Placeholder: FC<{children?: string}> = ({children}) => {
  return <div className='ml-4 font-medium text-neutral-80 text-fs-9'>{children}</div>
}

const ClassicCheckbox: FC<CheckboxItemProps> = ({placeholder, ...rest}) => {
  return (
    <Container>
      <div>
        <input type='checkbox' style={{backgroundColor: rest.checked ? '#B2152A' : '#F5F5F5'}} {...rest} />
      </div>
      {placeholder && <Placeholder>{placeholder}</Placeholder>}
    </Container>
  )
}

const SwitchCheckbox: FC<CheckboxItemProps> = ({placeholder, sizebox = 'normal', ...rest}) => {
  return (
    <Container>
      <div className='flex items-center cursor-pointer'>
        <div className='relative'>
          <input type='checkbox' className='sr-only' {...rest} />
          <div className={cn('backside bg-neutral-40 rounded-full', SwitchSizes[sizebox])}></div>
          <div
            className={cn(
              'dot absolute left-[2px] top-[2px] bg-white rounded-full transition duration-200',
              DotSizes[sizebox]
            )}
          ></div>
        </div>
      </div>
      {placeholder && <Placeholder>{placeholder}</Placeholder>}
    </Container>
  )
}

export const FormCheckbox: FC<FormCheckboxProps> = ({
  className,
  error,
  touched,
  variant = 'switch',
  checkedValue,
  changedValue,
  activeValue,
  inactiveValue,
  height = 'min-h-[44px]',
  ...rest
}) => {
  return (
    <div className={cn('flex items-center', height, className)}>
      {variant === 'classic' && (
        <ClassicCheckbox
          checked={checkedValue === activeValue}
          onChange={(e) => (changedValue ? changedValue(e) : null)}
          {...rest}
        />
      )}
      {variant === 'switch' && (
        <SwitchCheckbox
          checked={checkedValue === activeValue}
          onChange={(e) => (changedValue ? changedValue(e) : null)}
          {...rest}
        />
      )}

      {touched && error && <FormError>{error}</FormError>}
    </div>
  )
}
