import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {env} from 'src/app/utils/env-utils'
import {IItem} from 'src/app/mocks/menuitem'
import {IVisitor} from 'src/app/components/Features/Table/models/Table.model'

namespace OrderRedux {
  const STORAGE_KEY = () => {
    return env.REACT_APP_STORAGE_KEY || 'POS-order'
  }

  export interface ActionWithPayload<T> extends Action {
    payload?: T
  }

  export interface IOrderState {
    status?: FetchingStatusState
    isBookAndOrder?: boolean
    numberPax?: OrderNumber // Use the new type alias
    tableNumber?: OrderNumber // Use the new type alias
    menuItem?: any
    visitorType?: string
    tableName?: string
    salesMode?: any
    posId?: number
  }

  type OrderNumber = number | null | string

  export type FetchingStatusState = 'Dine In' | 'Take Away' | ''

  export const actionTypes = {
    SetStatus: '[SetStatus] Action',
    BookTableOnly: '[BookTableOnly] Action',
    AddMenu: '[AddMenu] Action',
    RemoveMenu: '[RemoveMenu] Action',
    UpdateTableVisitor: '[UpdateTableVisitor] Action',
    UpdateMenu: '[UpdateMenu] Action',
    OrderOnly: '[OrderOnly] Action',
    UpdateQuickService: '[UpdateQuickService] Action',
  }

  export const initialOrderState: IOrderState = {
    status: undefined,
    isBookAndOrder: false,
    numberPax: null,
    tableNumber: undefined,
    menuItem: [],
    visitorType: undefined,
    tableName: undefined,
    salesMode: undefined,
    posId: undefined,
  }

  export const reducer = () =>
    persistReducer<IOrderState, ActionWithPayload<IOrderState>>(
      {
        storage,
        key: STORAGE_KEY(),
        whitelist: [
          'status',
          'isBookAndOrder',
          'numberPax',
          'tableNumber',
          'menuItem',
          'visitorType',
          'tableName',
          'posId',
        ],
      },
      (state: IOrderState = initialOrderState, action = {type: ''}) => {
        switch (action.type) {
          case actionTypes.SetStatus: {
            const status = action.payload?.status
            return {status}
          }
          case actionTypes.BookTableOnly: {
            const visitorType = action.payload?.visitorType
            const numberPax = action.payload?.numberPax
            const tableNumber = action.payload?.tableNumber
            const tableName = action.payload?.tableName
            const status = action.payload?.status
            const salesMode = action.payload?.salesMode
            return {
              ...state,
              visitorType,
              numberPax,
              tableNumber,
              tableName,
              status,
              menuItem: [],
              salesMode,
              posId: undefined,
            }
          }

          case actionTypes.OrderOnly: {
            const salesMode = action.payload?.salesMode
            const numberPax = action.payload?.numberPax
            const status = action.payload?.status
            const visitorType = action.payload?.visitorType
            return {
              ...state,
              numberPax,
              status,
              salesMode,
              menuItem: [],
              visitorType,
              tableNumber: undefined,
              tableName: undefined,
              posId: undefined,
            }
          }
          case actionTypes.UpdateQuickService: {
            const salesMode = action.payload?.salesMode
            const numberPax = action.payload?.numberPax
            const status = action.payload?.status
            const posId = action.payload?.posId
            const visitorType = action.payload?.visitorType

            return {
              ...state,
              numberPax,
              status,
              salesMode,
              menuItem: [],
              posId,
              visitorType,
              tableNumber: undefined,
              tableName: undefined,
            }
          }
          case actionTypes.UpdateTableVisitor: {
            const visitorType = action.payload?.visitorType
            const numberPax = action.payload?.numberPax
            const salesMode = action.payload?.salesMode
            return {
              ...state,
              visitorType,
              numberPax,
              salesMode,
            }
          }
          case actionTypes.AddMenu:
          case actionTypes.UpdateMenu:
          case actionTypes.RemoveMenu: {
            const menuItem = action.payload?.menuItem
            return {
              ...state,
              menuItem,
            }
          }

          default:
            return state
        }
      }
    )

  export const actions = {
    setStatus: (status: string) => ({
      type: actionTypes.SetStatus,
      payload: {status},
    }),

    BookTableOnly: (
      visitorType: IVisitor,
      numberPax: number | string,
      tableNumber: string | number | null,
      tableName: string,
      status: FetchingStatusState,
      salesMode?: string | number | null
    ) => ({
      type: actionTypes.BookTableOnly,
      payload: {visitorType, numberPax, tableNumber, tableName, status, salesMode},
    }),
    OrderOnly: (
      salesMode: any,
      numberPax: number | string,
      status: FetchingStatusState,
      visitorType: any
    ) => ({
      type: actionTypes.OrderOnly,
      payload: {salesMode, numberPax, status, visitorType},
    }),
    UpdateQuickService: (
      salesMode: any,
      numberPax: number | string,
      status: FetchingStatusState,
      posId: number,
      visitorType: any
    ) => ({
      type: actionTypes.UpdateQuickService,
      payload: {salesMode, numberPax, status, posId, visitorType},
    }),
    UpdateTableVisitor: (visitorType: any, numberPax: number | string, salesMode: any) => ({
      type: actionTypes.UpdateTableVisitor,
      payload: {visitorType, numberPax, salesMode},
    }),
    AddMenu: (menuItem: Array<IItem>) => ({
      type: actionTypes.AddMenu,
      payload: {menuItem},
    }),
    UpdateMenu: (menuItem: Array<IItem>) => ({
      type: actionTypes.UpdateMenu,
      payload: {menuItem},
    }),
    RemoveMenu: (menuItem: Array<IItem>) => ({
      type: actionTypes.RemoveMenu,
      payload: {menuItem},
    }),
  }
}

export default OrderRedux
