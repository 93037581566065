/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {ChevronLeft, ChevronRight, ArrowDown, ArrowUp, Plus} from 'react-swm-icon-pack'
import {RootState} from 'src/setup'
import OrderModal from '../../Widgets/ModalCollection/OrderModal'
import NumberPad from '../../Libs/NumberPad/NumberPad.component'
import {checkJson} from 'src/app/utils/cek-json-utils'
import {useHistory} from 'react-router-dom'
import OrderRedux from 'src/app/modules/order/redux/OrderRedux'
import setupWebsocket from 'src/setup/websocket/SetupWebsocket'
import {format} from 'date-fns'
import {NumericFormat} from 'react-number-format'
import ListSalesMode from 'src/app/modules/order/Components/ListSalesMode.component'
import ListVisitorComponent from 'src/app/modules/order/Components/ListVisitor.components'
import {IVisitor} from '../Table/models/Table.model'
import {IUser} from 'src/app/models/api.types'
import {handleResponse} from 'src/setup/hooks/socketInterceptor'
import clsx from 'clsx'

export interface IFSalesMode {
  id: number
  name: string
  show_dine_in: boolean
  show_quick_service: boolean
  sales_mode_group_id: number
  kiosk_id: number
  show_queue: boolean
  max_order: boolean
  status: string
  created_at: Date
  updated_at: Date
  deleted_at: string
}

const QuickService: React.FC<{dataBranch: any}> = () => {
  const [data, setData] = useState<Array<any>>()

  const [currentPage, setCurrentPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [dateSortAsc, setDateSortAsc] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false)
  const [numberPax, setNumberPax] = useState('')
  const [salesMode, setSalesMode] = useState<any>()
  const user: IUser = useSelector<RootState>(
    ({auth}) => auth?.user,
    shallowEqual
  ) as unknown as IUser
  const dispatch = useDispatch()
  const history = useHistory()
  const [visitorType, setVisitorType] = useState<IVisitor | undefined>()
  const [listVisitor, setListVisitor] = useState<any>()
  const [pagination, setPagination] = useState<{page: number; total: number}>({
    page: currentPage,
    total: 10,
  })

  useEffect(() => {
    if (!listVisitor) {
      const payloadVisitor = {
        type: 'GET',
      }
      try {
        const wsVisitor = setupWebsocket('visitor_type', payloadVisitor)
        wsVisitor.onmessage = (event) => {
          const response = checkJson(event.data) ? JSON.parse(event.data) : ''
          if (response?.response_schema?.response_code === '200') {
            setListVisitor(response?.response_output.list.content)
          } else {
            handleResponse(event)
          }
        }
      } catch (error) {
        console.error('WebSocket error:', error)
      }
    }
  }, [listVisitor])

  const inputNumber = (number: number) => {
    const input = `${numberPax}${number}`
    if(input !== '0') setNumberPax(input)
  }

  useEffect(() => {
    if (!data) {
      getDataQuickService()
    }
  }, [data])

  useEffect(() => {
    getDataQuickService()
  }, [currentPage, rowsPerPage, dateSortAsc])

  const getDataQuickService = () => {
    const payload = {
      type: 'POST',
      data: {
        limit: rowsPerPage,
        page: currentPage,
        date_sort_asc: dateSortAsc
      },
    }

    const wsQs = setupWebsocket('quick_service', payload)
    wsQs.onmessage = (event) => {
      const response = checkJson(event.data) ? JSON.parse(event.data) : ''

      if (response?.response_schema?.response_code === '200') {
        setData(response.response_output.list.content)
        setPagination(response.response_output.list.pagination)
      } else {
        handleResponse(event)
      }
    }
  }

  const clearNumber = () => {
    setNumberPax('')
  }

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setCurrentPage(1)
  }

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const handleSortAsc = () => {
    setDateSortAsc(!dateSortAsc);
  }

  const continueOrder = () => {
    dispatch(OrderRedux.actions.OrderOnly(salesMode, numberPax, 'Take Away', visitorType))
    history.push('/order')
  }

  const handleSelecetOrder = (data: any) => {
    dispatch(
      OrderRedux.actions.UpdateQuickService(
        data.sales_mode_id,
        data.number_pax || 0,
        'Take Away',
        data.pos_id,
        visitorType
      )
    )
    history.push('/order')
  }
  const totalPages = Math.ceil((pagination.total || 0) / rowsPerPage)

  return (
    <div className='flex flex-col grow h-full bg-white rounded-lg border border-[#EAECF0]'>
      <div className='flex flex-col h-full'>
        <div className='flex justify-between px-5 py-6 text-lg font-poppins border-b border-[#EAECF0]'>
          Quick Service List
          {user && (
            <button
              className={`flex flex-row justify-center items-center px-4 py-3 gap-2 h-11 rounded-lg border text-sm bg-[#CF1E26] text-white w-50`}
              onClick={() => setShowModal(true)}
            >
              <Plus size={16} color={'#FFFFFF'} /> Add Quick Service
            </button>
          )}
        </div>
        <div className='flex-1 flex-col h-full'>
          <table className='min-w-full'>
            <thead className='border-b border-[#EAECF0] bg-[#F9FAFB] text-sm text-left text-[#667085]'>
              <tr>
                <td className='px-5 py-6'>Order Number</td>
                <td className='px-5 py-6 cursor-pointer' onClick={handleSortAsc}>
                  <div className='flex items-center gap-1'>
                    <span>Date</span>
                    {dateSortAsc ? (
                      <ArrowUp size={16} color={'#667085'} />
                    ) : (
                      <ArrowDown size={16} color={'#667085'} />
                    )}
                  </div>
                </td>
                <td className='px-5 py-6'>
                  <div className='flex items-center gap-1'>
                    <span>Pax Total</span>
                  </div>
                </td>
                <td className='px-5 py-6'>Member</td>
                <td className='px-5 py-6'>Additional Info</td>
                <td className='px-5 py-6'>
                  <div className='flex items-center gap-1'>
                    <span>Grand Total</span>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              {data && data?.length > 0 ? (
                data.map((row, index) => (
                  <tr
                    key={index}
                    className='border-b border-[#EAECF0] text-xs text-left text-[#101828] cursor-pointer'
                    onClick={() => handleSelecetOrder(row)}
                  >
                    <td className='px-5 py-6'>{row.order_number}</td>
                    <td className='px-5 py-6'>
                      {format(new Date(row.created_at), 'yyyy-MM-dd HH:mm:ss')}
                    </td>
                    <td className='px-5 py-6'>{row.total_pax}</td>
                    <td className='px-5 py-6'>{row.member_name}</td>
                    <td className='px-5 py-6'>{row.additional_info}</td>
                    <td className='px-5 py-6'>
                      <NumericFormat
                        value={row.grand_total || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'Rp'}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>
                    <div className='flex text-sm justify-center w-full h-[30vh] items-center'>
                      No data
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='flex flex-row justify-between px-3 py-6 border-t border-[#EAECF0]'>
          <div className='flex flex-row items-center grow gap-3'>
            <select
              value={rowsPerPage}
              onChange={handleChangeRowsPerPage}
              className='ml-2 bg-[#ffffff] border border-solid border-[#D0D5DD] px-2.5 py-2 w-[60px] h-9'
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <label className='text-xs text-[#667085]'>Rows per page</label>
          </div>
          <div className='flex items-center gap-3'>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1 || data?.length === 0}
              className={clsx('border-0 p-5', {
                'opacity-40 cursor-not-allowed' : currentPage === 1 || data?.length === 0
              })}
            >
              <ChevronLeft />
            </button>
            <span className='px-5 py-3 rounded-lg border border-[#F08575] text-xs text-[#475467] bg-[#FEF7F3]'>
              {currentPage}
            </span>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages || data?.length === 0}
              className={clsx('border-0 p-5', {
                'opacity-40 cursor-not-allowed' : currentPage === totalPages || data?.length === 0
              })}
            >
              <ChevronRight />
            </button>
          </div>
        </div>
      </div>

      <OrderModal
        show={showModal}
        size='sm'
        handleClose={() => setShowModal(false)}
        handleActionOne={() => setShowModal(false)}
        handleActionTwo={() => continueOrder()}
        header='Quick Service'
        positiveLabel='Continue'
        disabledButton={
          !salesMode ||
          !numberPax ||
          (listVisitor?.filter((item: IVisitor) => item.show_quick_service).length > 0
            ? !visitorType
            : false)
        }
        negativeLabel='Cancel'
        statusOrder='TAKE AWAY'
      >
        <div className='flex flex-col gap-4 w-94 h-204 rounded-lg'>
          <div className='flex flex-col items-start p-0 gap-7 w-full'>
            {/* Input field */}
            <div className='flex w-full flex-col p-0 gap-1 h-11 mb-3'>
              <span className='text-sm'>Number of Pax</span>
              <input
                type='text'
                className={`box-border text-center flex items-center p-3 gap-2 h-11 bg-white border border-solid border-[#D0D5DD] shadow-[0_1px_2px_rgba(16,24,40,0.05)] rounded-lg`}
                placeholder='Enter Number of Pax'
                readOnly={true}
                value={numberPax}
              />
            </div>
            <NumberPad
              onInput={(value) => {
                if (numberPax?.length < 6) {
                  inputNumber(value)
                }
              }}
              onClear={() => clearNumber()}
            ></NumberPad>
          </div>
          <ListVisitorComponent
            visitors={visitorType}
            onSelecetVisitor={(data: IVisitor) => setVisitorType(data)}
            listVisitor={listVisitor}
            type='TAKE_AWAY'
          />
          <ListSalesMode
            salesMode={salesMode}
            setSalesMode={(sales) => setSalesMode(sales)}
            type='TAKE_AWAY'
          />
        </div>
      </OrderModal>
    </div>
  )
}

export default QuickService
